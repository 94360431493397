import type { LngLatLike } from 'svelte-maplibre';
import { TrackingDataSubscription } from '../store/app.store';
import RestCall from './api';
import type { TrackingData } from './types';
import type { CoordinateTuple, Maybe } from '../types/common.type';
import { TrackingState } from '../constants';
import ConnectWebSocket from './socket';

const getTrackingData = async (trackingId: string, showInferences: boolean): Promise<TrackingData> => {
  try {
    const result: TrackingData = await RestCall({
      path: `/tracking/${trackingId}?include_inferences=${showInferences}`,
    });

    const { order, driver } = result;
    let driverCoords: Maybe<CoordinateTuple>;
    let trackingState = TrackingState.Ongoing;

    if (driver?.location?.geometry?.coordinates?.length > 0) {
      driverCoords = [driver.location.geometry.coordinates[0], driver.location.geometry.coordinates[1]];
    }

    if (order.completed_at) {
      trackingState = TrackingState.Completed;
    } else if (order.arrived_at) {
      trackingState = TrackingState.Arrived;
    }

    if (!order.completed_at) {
      ConnectWebSocket(trackingId);
    }
    TrackingDataSubscription.update((prev) => ({
      ...prev,
      compeletedAt: order.completed_at,
      arrivedAt: order.arrived_at,
      state: trackingState,
      destination: {
        address: order?.destination?.address,
        geometry: order?.destination?.geometry,
        radius: order?.destination?.radius,
      },
      estimatePolyline: (order.estimate?.polyline?.coordinates as Array<LngLatLike>) || [],
      actualPolyline: order?.summary?.locations?.map((location) => [location.lon, location.lat]),
      actualDistance: order.summary?.distance,
      actualDuration: order.summary?.duration,
      driverLocation: {
        ...prev.driverLocation,
        bearing: driver?.location?.bearing || 0,
        coords: driverCoords,
      },
    }));
    return result;
  } catch (error: any) {
    console.log(error);
    const errorText =
      error?.detail ||
      "We're sorry, but it seems like there was an issue with your request, or we couldn't find the tracking information you're looking for.";
    throw new Error(errorText);
  }
};

export default getTrackingData;
