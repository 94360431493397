<!-- OrderDetails.svelte -->
<script lang="ts">
  import { OrderStatus } from '../lib/types';
  import type { GenericObjectType } from '../types/common.type';

  export let status: OrderStatus;
  export let metadata: GenericObjectType;

  function getOrderNotes() {
    if (status === OrderStatus.Completed) {
      if (metadata.completion_options) {
        const completionOptions = metadata.completion_options;
        return completionOptions.length > 0 ? completionOptions[0] : null;
      }
    } else if (status === OrderStatus.Cancelled) {
      if (metadata.cancellation_options) {
        const cancellationOptions = metadata.cancellation_options;
        return cancellationOptions.length > 0 ? cancellationOptions[0] : null;
      }
    }
    return null;
  }

  let orderNotes = getOrderNotes();
</script>

{#if orderNotes !== null}
  <p
    class="font-semibold"
    class:text-green-600={status === OrderStatus.Completed}
    class:text-red-600={status === OrderStatus.Cancelled}
  >
    <span class="text-black">Note:</span>
    {orderNotes}
  </p>
{/if}
