<script lang="ts">
  import { GeoJSON, LineLayer, type LngLatLike } from 'svelte-maplibre';
  import type { GenericObjectType } from '../../types/common.type';
  import { lineString } from '@turf/helpers';

  export let id: string;
  export let polylineData: LngLatLike[];
  export let layout: GenericObjectType;
  export let paint: GenericObjectType;

  $: data = polylineData?.length > 1 ? lineString(polylineData) : [];
</script>

<GeoJSON {id} {data}>
  <LineLayer {layout} {paint} />
</GeoJSON>
