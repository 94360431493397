<!-- AttachmentItem.svelte -->
<script lang="ts">
  import type { Attachment } from '../../lib/types';

  export let attachment: Attachment;
</script>

{#if attachment.type === 'image' && 'url' in attachment}
  <div class="rounded-lg border border-gray-200 p-2">
    <img src={attachment.url} alt={attachment.caption} class="h-auto max-h-64 w-full md:max-h-80" />
    <p class="text-gray-600">{attachment.caption}</p>
  </div>
{:else if attachment.type === 'signature' && 'url' in attachment}
  <div class="rounded-lg border border-gray-200 p-2">
    <img src={attachment.url} alt="Signature" class="h-auto w-full" />
  </div>
{:else if attachment.type === 'note' && 'data' in attachment}
  <div class="rounded-lg border border-gray-200 p-2">
    <p class="text-gray-800">{attachment.data}</p>
  </div>
{:else}
  <div class="rounded-lg border border-gray-200 p-2">
    <p class="text-red-600">Unsupported attachment type</p>
  </div>
{/if}
