<script lang="ts">
  import DriverIcon from '../icons/DriverIcon.svelte';
  import type { DriverLocationType } from '../lib/types';
  import { LayerSubscription, TrackingDataSubscription } from '../store/app.store';
  import { lineString } from '@turf/helpers';
  import turfAlong from '@turf/along';
  import turfLength from '@turf/length';
  import { AllowedURLParmas, AllowedDriverIcons } from '../constants';
  import { getParamValue } from '../lib/utils';
  import AmbulanceIcon from '../icons/AmbulanceIcon.svelte';
  import type { LngLatLike } from 'maplibre-gl';
  import type { Maybe } from '../types/common.type';
  import { Marker } from 'svelte-maplibre';

  let driverRotation: number;
  let driverLocation: Maybe<LngLatLike>;
  let isDark = false;
  let icon = getParamValue(AllowedURLParmas.icon);

  function animateMarker(locationObj: DriverLocationType) {
    if (locationObj.animate && driverLocation) {
      const duration = 2000; // Animation duration in milliseconds

      const line = lineString([driverLocation, locationObj.coords]);
      const distance = turfLength(line, { units: 'kilometers' });

      let animationStartTime: number;

      function stepAnimation(timestamp: number) {
        if (!animationStartTime) {
          animationStartTime = timestamp;
        }
        const elapsed = timestamp - animationStartTime;
        const progress = Math.min(elapsed / duration, 1);

        if (progress < 1) {
          const along = turfAlong(line, progress * distance, { units: 'kilometers' });
          const newLngLat = along.geometry.coordinates;
          driverLocation = newLngLat;
          requestAnimationFrame(stepAnimation);
        }
      }

      // Start the animation
      requestAnimationFrame(stepAnimation);
    } else {
      driverLocation = locationObj.coords;
    }
  }

  TrackingDataSubscription.subscribe((data) => {
    animateMarker(data.driverLocation);
    driverRotation = data.driverLocation.bearing || 0;
  });
  LayerSubscription.subscribe((value) => {
    isDark = value.dark;
  });
</script>

{#if driverLocation}
  <Marker lngLat={driverLocation}>
    {#if icon === AllowedDriverIcons.Ambulance}
      <AmbulanceIcon class={isDark ? 'text-white' : 'text-black'} style={`transform: rotate(${driverRotation}deg)`} />
    {:else}
      <DriverIcon class={isDark ? 'text-white' : 'text-black'} style={`transform: rotate(${driverRotation}deg)`} />
    {/if}
  </Marker>
{/if}
