<script lang="ts">
  import type { LngLatLike } from 'maplibre-gl';
  import { DarkEstimatePathPaint, EstimatePathLayout, EstimatePathPaint } from '../constants';
  import { trimRoute } from '../lib/utils';
  import { LayerSubscription, TrackingDataSubscription } from '../store/app.store';
  import Polyline from './Map/Polyline.svelte';
  import type { Map } from 'svelte-maplibre';

  let polylineData: LngLatLike[] = [];
  let paint = EstimatePathPaint;
  export let map: Map | null;

  TrackingDataSubscription.subscribe(({ estimatePolyline, driverLocation }) => {
    if (driverLocation.coords) {
      polylineData = trimRoute(driverLocation.coords, estimatePolyline);
    } else {
      polylineData = estimatePolyline;
    }
  });

  LayerSubscription.subscribe((layer) => {
    map?.on('styledata', () => {
      paint = layer.dark ? DarkEstimatePathPaint : EstimatePathPaint;
    });
  });
</script>

<Polyline id="estimate" {polylineData} {paint} layout={EstimatePathLayout} />
