<script lang="ts">
  import { FillLayer, GeoJSON, LineLayer, Marker, type LayerClickInfo } from 'svelte-maplibre';
  import type { GenericObjectType } from '../../types/common.type';
  import { polygon } from '@turf/helpers';
  import centerOfMass from '@turf/center-of-mass';

  export let id: string;
  export let paint: GenericObjectType;
  export let outlineColor: string;
  export let coordinates: number[][][];
  export let polygonClick: ((e: CustomEvent<LayerClickInfo>) => void) | undefined = undefined;

  const data = polygon(coordinates);
  const center = centerOfMass(data)?.geometry?.coordinates;
</script>

<GeoJSON {id} {data}>
  <FillLayer {paint} on:click={polygonClick} />
  <LineLayer
    layout={{ 'line-cap': 'round', 'line-join': 'round' }}
    paint={{ 'line-color': outlineColor, 'line-width': 1 }}
    beforeLayerType="symbol"
  />
</GeoJSON>

<Marker lngLat={center}>
  <slot />
</Marker>
