import { LngLatBounds, type LngLatLike } from 'maplibre-gl';
import duration from 'dayjs/plugin/duration';
import dayjs from 'dayjs';
import { ColorKey, ColorMap, LocationInferenceType } from '../constants';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import { lineString, point } from '@turf/helpers';
import lineSplit from '@turf/line-split';
import * as Sentry from '@sentry/svelte';
import type {
  AllowedParameterKey,
  ChunkedMessages,
  ColorObj,
  CoordinateTuple,
  Maybe,
  PointOrBounds,
} from '../types/common.type';
import type { LocationInferenceData } from '../types/inferences.type';

dayjs.extend(duration);

export function isNotNullOrUndefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export const roundToTwo = (num: number) => +num.toFixed(2);

export const meterToKm = (value: Maybe<number>): number => (typeof value === 'number' ? value / 1000 : NaN);

export function meterToKmString(value: Maybe<number>): Maybe<string> {
  if (typeof value === 'number') {
    return `${roundToTwo(value / 1000)} km`;
  }
}

export const prettyDuration = (milliseconds: number | null | undefined) => {
  let formattedDuration = '';
  if (isNotNullOrUndefined(milliseconds)) {
    const durationObject = dayjs.duration(Math.abs(milliseconds));
    if (durationObject?.days()) {
      formattedDuration += `${durationObject.days()} ${durationObject.days() > 1 ? 'days' : 'day'} `;
    }
    if (durationObject?.hours()) {
      formattedDuration += `${durationObject.hours()} hr `;
    }
    if (durationObject?.minutes()) {
      formattedDuration += `${durationObject.minutes()} min`;
    }
    if (formattedDuration === '') {
      formattedDuration = '0 min';
    }
  }
  return formattedDuration;
};

/**
 * Combine bounds of array of point or bounds elements
 */
export function reduceBounds(elements: Maybe<PointOrBounds>[]) {
  const bounds = new LngLatBounds();
  elements.forEach((element) => {
    if (element) {
      bounds.extend(element);
    }
  });
  return bounds;
}

export function getParamValue(paramName: AllowedParameterKey) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(paramName) ? urlParams.get(paramName) : null;
}

export const isCoordEqual = (cord1: Maybe<CoordinateTuple>, cord2: Maybe<CoordinateTuple>): boolean => {
  return cord1?.[0] === cord2?.[0] && cord1?.[1] === cord2?.[1];
};

export const durationDiffText = (diff: number): string => (diff >= 0 ? 'early' : 'late');

export const getColor = (diff: number | null): ColorObj => {
  let color = ColorMap[ColorKey.Gray];
  if (isNotNullOrUndefined(diff)) {
    color = diff >= 0 ? ColorMap[ColorKey.Green] : ColorMap[ColorKey.Red];
  }
  return color;
};

export function trimRoute(location: LngLatLike, polyline: LngLatLike[]) {
  if (polyline.length > 0) {
    const line = lineString(polyline);

    // Create a Turf Point representing the driver's current location
    const driverPoint = point(location);

    // Find the closest point on the polyline to the driver's location
    const nearestPoint = nearestPointOnLine(line, driverPoint);

    // Split the polyline into two parts, removing the section traveled by the driver
    const options = { units: 'kilometers' }; // You can adjust the units as needed
    const result = lineSplit(line, nearestPoint, options);

    return result.features[1]?.geometry?.coordinates || result.features[0]?.geometry?.coordinates;
  } else return [];
}

/* This implementation assumes that n is always in the correct order, and it directly assigns the msg to the correct index in the chunks array. */

export function processChunk(
  messages: ChunkedMessages,
  data: string,
  onCompleteCallback: (message: string) => void,
): void {
  const { id, msg, n, total } = JSON.parse(data);

  if (!messages[id]) {
    messages[id] = { chunks: [], sequenceNumbers: new Set() };
  }
  if (n < total) {
    messages[id].chunks[n] = msg;
    messages[id].sequenceNumbers.add(n);

    if (messages[id].sequenceNumbers.size === total) {
      // Call the callback with the concatenated message
      onCompleteCallback(messages[id].chunks.join(''));

      // Remove the array from the messages object
      delete messages[id];
    }
  } else {
    console.error(data);
    Sentry.captureException('N is greater than or equals to total chunks for ws message');
  }
}

export function extractInferenceTypes(inferences?: LocationInferenceData[]): string | LocationInferenceType {
  if (!inferences) {
    console.error('Input array is undefined or null');
    return '';
  }

  const typesArray = inferences.map((inference) => inference.type);

  if (typesArray.includes(LocationInferenceType.Parking) && typesArray.includes(LocationInferenceType.Checkin)) {
    // If both Parking and Checkin are present, return 'Both'
    return 'Both';
  } else if (typesArray.includes(LocationInferenceType.Parking)) {
    // If only Parking is present, return 'Parking'
    return LocationInferenceType.Parking;
  } else if (typesArray.includes(LocationInferenceType.Checkin)) {
    // If only Checkin is present, return 'Checkin'
    return LocationInferenceType.Checkin;
  } else {
    // If neither Parking nor Checkin is present, return an empty string
    return '';
  }
}

export function getCountByType(
  inferences?: LocationInferenceData[],
  targetType?: LocationInferenceType,
): number | undefined {
  if (!inferences || !targetType) {
    console.error('Input array or targetType is undefined or null');
    return undefined;
  }

  const matchingInference = inferences.find((inference) => inference.type === targetType);

  return matchingInference ? matchingInference.count : undefined;
}
