<script lang="ts">
  import type { CoordinateTuple, Maybe } from '../types/common.type';
  import BellIcon from '../icons/BellIcon.svelte';
  import { CheckinPolygonOutline, CheckinPolygonPaint } from '../constants';
  import { polygon } from '@turf/helpers';
  import centerOfMass from '@turf/center-of-mass';
  import Polygon from './Map/Polygon.svelte';
  import { Marker, type Map } from 'svelte-maplibre';

  export let id: string;
  export let polyline: Maybe<number[][][]>;
  export let count: Maybe<number> = 0;
  let clicked = false;
  let center: CoordinateTuple | null = null;

  if (polyline) {
    const polygonData = polygon(polyline);
    center = centerOfMass(polygonData)?.geometry?.coordinates as CoordinateTuple;
  }

  const toggleClick = function () {
    clicked = !clicked;
  };
</script>

{#if polyline}
  {#if clicked && center}
    <Marker lngLat={center} offset={[-3, -27]}>
      <div class="relative rounded border bg-white px-1 text-black shadow-md">
        <span class="font-semibold">~{count}</span> checkins here
        <div class="absolute -bottom-1 left-1/2 h-2 w-2 rotate-45 border-b border-r border-solid bg-white"></div>
      </div>
    </Marker>
  {/if}
  <Polygon
    {id}
    coordinates={polyline}
    paint={CheckinPolygonPaint}
    outlineColor={CheckinPolygonOutline}
    polygonClick={toggleClick}
  >
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div
      class="flex h-6 items-center justify-center rounded-full bg-green-600 px-2 font-semibold leading-none text-white"
    >
      <BellIcon width={14} height={14} class="text-white" />
    </div>
  </Polygon>
{/if}
