<script lang="ts">
  import { TrackingDataSubscription } from '../store/app.store';
  import { DestinationPolygonOutline, DestinationPolygonPaint } from '../constants';

  import type { LngLatLike } from 'maplibre-gl';
  import type { Geometry } from '../lib/types';
  import EndLocationIcon from '../icons/EndLocationIcon.svelte';
  import Polygon from './Map/Polygon.svelte';
  import Circle from './Map/Circle.svelte';

  let destinationGeometry: Geometry;
  let center: LngLatLike;
  let raidus: number;

  TrackingDataSubscription.subscribe((data) => {
    if (data.destination.geometry) {
      destinationGeometry = data.destination.geometry;
    }
    if (destinationGeometry?.type === 'Point') {
      // @ts-ignore
      center = destinationGeometry.coordinates;
      raidus = data.destination?.radius || 0;
    }
  });
</script>

{#if destinationGeometry?.type === 'Polygon'}
  <Polygon
    id="destination_polygon"
    coordinates={destinationGeometry.coordinates}
    paint={DestinationPolygonPaint}
    outlineColor={DestinationPolygonOutline}
  >
    <EndLocationIcon />
  </Polygon>
{:else if destinationGeometry?.type === 'Point'}
  <Circle
    id="destination_circle"
    {center}
    {raidus}
    paint={DestinationPolygonPaint}
    outlineColor={DestinationPolygonOutline}
  />
{/if}
