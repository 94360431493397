<script lang="ts">
  import type { LngLatLike } from 'maplibre-gl';
  import { ActualPathLayout, ActualPathPaint, DarkActualPathPaint } from '../constants';
  import { LayerSubscription, TrackingDataSubscription } from '../store/app.store';
  import Polyline from './Map/Polyline.svelte';
  import { Marker, type Map } from 'svelte-maplibre';
  import StartLocationIcon from '../icons/StartLocationIcon.svelte';
  import { onDestroy } from 'svelte';

  let polylineData: LngLatLike[] = [];
  let orderStartLocation: LngLatLike;
  let paint = ActualPathPaint;
  export let map: Map | null;

  const trackingUnsubscribe = TrackingDataSubscription.subscribe(({ actualPolyline }) => {
    polylineData = actualPolyline;
    if (polylineData?.length > 0) {
      orderStartLocation = polylineData[0] as LngLatLike;
    }
  });

  const layerUnsubscirbe = LayerSubscription.subscribe((layer) => {
    map?.on('styledata', () => {
      paint = layer.dark ? DarkActualPathPaint : ActualPathPaint;
    });
  });

  onDestroy(() => {
    trackingUnsubscribe();
    layerUnsubscirbe();
  });
</script>

<Polyline id="actual" {polylineData} {paint} layout={ActualPathLayout} />

{#if orderStartLocation}
  <Marker lngLat={orderStartLocation}>
    <StartLocationIcon width={14} height={14} />
  </Marker>
{/if}
