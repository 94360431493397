<script lang="ts">
  import type { LngLatLike } from 'maplibre-gl';
  import { FillLayer, GeoJSON, LineLayer, Marker } from 'svelte-maplibre';
  import turfCircle from '@turf/circle';
  import type { GenericObjectType } from '../../types/common.type';
  import EndLocationIcon from '../../icons/EndLocationIcon.svelte';

  export let id: string;
  export let paint: GenericObjectType;
  export let outlineColor: string;
  export let center: LngLatLike;
  export let raidus: number;

  const data = turfCircle(center, raidus, {
    units: 'meters',
  });
</script>

<GeoJSON {id} {data}>
  <FillLayer {paint} />
  <LineLayer
    layout={{ 'line-cap': 'round', 'line-join': 'round' }}
    paint={{ 'line-color': outlineColor, 'line-width': 1 }}
    beforeLayerType="symbol"
  />
</GeoJSON>

<Marker lngLat={center}>
  <EndLocationIcon width={14} height={14} />
</Marker>
