import type { LngLatLike } from 'maplibre-gl';
import type { CoordinateTuple, GenericObjectType, GeometryType, Maybe } from '../types/common.type';
import type { Driver } from '../types/driver.type';
import type { Inferences } from '../types/inferences.type';
import type { TrackingState } from '../constants';

export interface TrackingData {
  driver: Driver;
  order: Order;
  inferences: Inferences;
}

interface ImageAttachment {
  type: 'image';
  url: string;
  caption: string;
}

interface SignatureAttachment {
  type: 'signature';
  url: string;
}

interface NoteAttachment {
  type: 'note';
  data: string;
}

export type Attachment = ImageAttachment | SignatureAttachment | NoteAttachment;

export enum OrderStatus {
  Accepted = 'accepted',
  Assigned = 'assigned',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Disabled = 'disabled',
  Dispatched = 'dispatched',
  Failed = 'failed',
  Ongoing = 'ongoing',
  Paused = 'paused',
  Planned = 'planned',
  Removed = 'removed',
  Rescheduled = 'rescheduled',
  Routed = 'routed',
  Snoozed = 'snoozed',
  Summarizing = 'summarizing',
  Unplanned = 'unplanned',
}

export interface Order {
  arrived_at: string;
  assigned_at: any;
  completed_at: string;
  attachments: Attachment[];
  destination: Destination;
  estimate: Estimate;
  exited_at: string;
  order_handle: string;
  planned_at: any;
  route_handle: string;
  scheduled_at: string;
  start_address: string;
  started_at: string;
  summary: Summary;
  metadata: GenericObjectType;
  status: OrderStatus;
}

export interface Destination {
  address: Maybe<string>;
  geometry: Maybe<Geometry>;
  radius: Maybe<number>;
}

export interface Geometry {
  coordinates: number[][][];
  type: GeometryType;
}

export interface Estimate {
  arrive_at: any;
  distance: number;
  duration: number;
  end_location: any;
  polyline: Polyline;
  start_by: any;
  start_location: any;
}

export interface Polyline {
  coordinates: LngLatLike[];
  type: string;
}

export interface Summary {
  distance: number;
  duration: number;
  locations: Location[];
}

export interface Location {
  lat: number;
  lon: number;
  timestamp: string;
}

export type DriverLocationType = {
  coords: Maybe<CoordinateTuple>;
  animate: Boolean;
  bearing: number;
};

export type TrackingDataSubscriptionType = {
  destination: Destination;
  state: TrackingState;
  arrivedAt: Maybe<string>;
  compeletedAt: Maybe<string>;
  remainingDuration: Maybe<number>;
  remainingDistance: Maybe<number>;
  driverLocation: DriverLocationType;
  estimatePolyline: Array<LngLatLike>;
  actualPolyline: Array<LngLatLike>;
  actualDuration: Maybe<number>;
  actualDistance: Maybe<number>;
};
