<script lang="ts">
  import { HeartCrack } from 'lucide-svelte';

  export let errorText: any;
</script>

{#if errorText}
  <div class="absolute z-20 flex h-screen w-full flex-col items-center justify-center bg-white text-lg">
    <HeartCrack class="h-28 w-28 text-red-600" />
    <h2>{errorText}</h2>
    <div class="mt-2">
      You can contact us via email at <a class="text-green-600 underline" href="mailto:support@hypertrack.com"
        >support@hypertrack.com</a
      >
      or
      <a class="text-green-600 underline" href="https://hypertracksupport.slack.com/archives/C1587AQGM"
        >Join our Slack channel.</a
      >
      Our team is here to help!
    </div>
  </div>
{/if}
