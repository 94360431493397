import type { ColorMapType } from './types/common.type';

export type LayerType = 'street' | 'light' | 'dark' | 'statellite';

export type MapLayerType = {
  label: string;
  id: LayerType;
  styleURL: string;
  dark: boolean;
};

// The first item of map layer is default Item so to change default just shift the position
export const MAP_LAYERS: MapLayerType[] = [
  {
    label: 'Light',
    id: 'light',
    dark: false,
    styleURL: `https://maps.geo.${import.meta.env.VITE_AWS_REGION}.amazonaws.com/maps/v0/maps/${
      import.meta.env.VITE_LIGHT_MAP_NAME
    }/style-descriptor?key=${import.meta.env.VITE_AWS_MAP_KEY}`,
  },
  {
    label: 'Street',
    id: 'street',
    dark: false,
    styleURL: `https://maps.geo.${import.meta.env.VITE_AWS_REGION}.amazonaws.com/maps/v0/maps/${
      import.meta.env.VITE_BASE_MAP_NAME
    }/style-descriptor?key=${import.meta.env.VITE_AWS_MAP_KEY}`,
  },
  {
    label: 'Dark',
    id: 'dark',
    dark: true,
    styleURL: `https://maps.geo.${import.meta.env.VITE_AWS_REGION}.amazonaws.com/maps/v0/maps/${
      import.meta.env.VITE_DARK_MAP_NAME
    }/style-descriptor?key=${import.meta.env.VITE_AWS_MAP_KEY}`,
  },
  {
    label: 'Satellite',
    id: 'statellite',
    dark: false,
    styleURL: `https://maps.geo.${import.meta.env.VITE_AWS_REGION}.amazonaws.com/maps/v0/maps/${
      import.meta.env.VITE_SATELLITE_MAP_NAME
    }/style-descriptor?key=${import.meta.env.VITE_AWS_MAP_KEY}`,
  },
];

export const DATE_FORMAT = 'HH:mm ddd MMM DD';

export const ActualPathPaint = {
  'line-color': '#000',
  'line-width': 2,
};

export const DarkActualPathPaint = {
  'line-color': '#fff',
  'line-width': 2,
};

export const ActualPathLayout = {
  'line-join': 'round',
  'line-cap': 'round',
};

export const EstimatePathPaint = {
  'line-color': '#000',
  'line-width': 2,
};

export const DarkEstimatePathPaint = {
  'line-color': '#fff',
  'line-width': 2,
};

export const EstimatePathLayout = {
  'line-join': 'round',
  'line-cap': 'round',
};

export const DestinationPolygonPaint = {
  'fill-color': '#999999',
  'fill-opacity': 0.3,
};

export const ParkingPolygonPaint = {
  'fill-color': '#20a7db',
  'fill-opacity': 0.2,
};

export const ParkingPolygonOutline = '#20a7db';

export const CheckinPolygonPaint = {
  'fill-color': '#27A359',
  'fill-opacity': 0.2,
};

export const CheckinPolygonOutline = '#27A359';

export const OverlapPolygonPaint = {
  'fill-color': '#a32771',
  'fill-opacity': 0.2,
};

export const OverlapPolygonOutline = '#a32771';

export const DestinationPolygonOutline = '#999';

export const AllowedURLParmas = Object.freeze({
  mapOnly: 'map_only',
  tileType: 'tile_type',
  hideControls: 'hide_controls',
  startStageText: 'start_stage_text',
  hideCompleted: 'hide_completed',
  showAttachments: 'show_attachments',
  showTerminalNotes: 'show_terminal_notes',
  completeStageText: 'complete_stage_text',
  enrouteStageText: 'enroute_stage_text',
  showInferences: 'show_inferences',
  icon: 'icon',
} as const);

export enum AllowedDriverIcons {
  Ambulance = 'ambulance',
}

export enum TrackingState {
  Completed = 'completed',
  Ongoing = 'ongoing',
  Arrived = 'arrived',
}

export enum ColorKey {
  Gray = 'gray',
  Red = 'red',
  Green = 'green',
}

export enum LocationInferenceType {
  Parking = 'parking',
  Checkin = 'checkin',
}

export const ColorMap: ColorMapType = {
  [ColorKey.Gray]: {
    text: 'text-gray-400',
    border: 'border-gray-500',
  },
  [ColorKey.Red]: {
    text: 'text-red-600',
    border: 'border-red-500',
  },
  [ColorKey.Green]: {
    text: 'text-green-600',
    border: 'border-green-500',
  },
};
