import * as Sentry from '@sentry/svelte';
import type { GenericObjectType } from '../types/common.type';

interface RestCallType {
  options?: RequestInit;
  data?: GenericObjectType;
}

interface RestFullURL extends RestCallType {
  fullURL: string;
  path?: never;
}
interface RestPath extends RestCallType {
  fullURL?: never;
  path: string;
}

const RestCall = async (param: RestFullURL | RestPath): Promise<any> => {
  const apiUrl = param.fullURL || import.meta.env.VITE_API_DOMAIN + param.path;

  let options = {
    ...param.options,
  };

  options.headers = {
    'Content-Type': 'application/json',
    'x-ht-internal-source': `${import.meta.env.MODE}_track.at`,
    ...(param.options?.headers || {}),
  };

  if (param.data) {
    options.body = JSON.stringify(param.data);
  }
  const response = await fetch(apiUrl, options);
  const apiData = await response.json();

  if (response.ok) {
    return apiData;
  } else {
    const errorObject = {
      statusCode: response.status,
      ...(apiData || {}),
    };
    Sentry.captureException(errorObject);
    // Throw an error with the error object
    throw errorObject;
  }
};

export default RestCall;
