import './app.css';
import App from './App.svelte';

import * as Sentry from '@sentry/svelte';

const dsn = import.meta.env.VITE_SENTRY_DSN;

if (dsn) {
  console.debug('registerd sentry');
  Sentry.init({
    dsn,
    environment: import.meta.env.MODE,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          import.meta.env.VITE_HOSTED_DOMAIN,
          import.meta.env.VITE_FF_DOMAIN,
          import.meta.env.VITE_API_DOMAIN,
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const app = new App({
  target: document.getElementById('app')!,
});

export default app;
