<script lang="ts">
  import { AllowedURLParmas, DATE_FORMAT, TrackingState } from '../constants';
  import { Flag, Bell, Check } from 'lucide-svelte';
  import { type Order } from '../lib/types';
  import { Paperclip } from 'lucide-svelte';
  import dayjs, { Dayjs } from 'dayjs';
  import {
    durationDiffText,
    getColor,
    getParamValue,
    isNotNullOrUndefined,
    meterToKmString,
    prettyDuration,
  } from '../lib/utils';
  import TimelineIcon from './TimelineIcon.svelte';
  import { onDestroy, onMount } from 'svelte';
  import { TrackingDataSubscription } from '../store/app.store';
  import AttachmentModal from './attachment/AttachmentModal.svelte';
  import OrderNotes from './OrderNotes.svelte';
  import type { Driver } from '../types/driver.type';
  import type { Maybe, ValueOf } from '../types/common.type';

  export let order: Order;
  export let driver: Driver;

  let orderStatus: ValueOf<TrackingState>;
  let arrivedDuration: number;
  let completedDuration: number;
  let remainingDuration: number;
  let arrivedAt: Maybe<string>;
  let completedAt: Maybe<string>;
  let percent: number = 0;
  let time = new Date();
  let eta: Dayjs;
  let etaDiff: number;
  let isModalOpen = false;
  let interval: number;
  let duration: number;
  let distance: Maybe<number>;
  let borderColor: string;

  const startStageText = getParamValue(AllowedURLParmas.startStageText) || 'Order Started';
  const completeStageText = getParamValue(AllowedURLParmas.completeStageText) || 'Order Marked Complete';
  const enrouteStageText = getParamValue(AllowedURLParmas.enrouteStageText) || 'Order yet to Complete';
  const hideCompleted = getParamValue(AllowedURLParmas.hideCompleted) === 'true';
  const showAttachment = getParamValue(AllowedURLParmas.showAttachments) === 'true';
  const showTerminalNotes = getParamValue(AllowedURLParmas.showTerminalNotes) === 'true';

  const calcBorderColor = () => {
    let diff;
    if (orderStatus === TrackingState.Ongoing && eta && order.scheduled_at) {
      diff = dayjs(order.scheduled_at).diff(eta);
    } else if (arrivedAt) {
      diff = dayjs(order.scheduled_at).diff(arrivedAt);
    } else {
      diff = dayjs(order.scheduled_at).diff(completedAt);
    }
    borderColor = getColor(diff).border;
  };

  const unsubcribeTrackingData = TrackingDataSubscription.subscribe((value) => {
    orderStatus = value.state;
    duration = value.actualDuration || 0;
    distance = value.actualDistance;
    if (value.remainingDuration) {
      remainingDuration = value.remainingDuration;
    }
    arrivedAt = value?.arrivedAt;
    completedAt = value?.compeletedAt;
    calcBorderColor();
  });

  function openModal() {
    isModalOpen = true;
  }

  function onCloseModal() {
    isModalOpen = false;
  }

  $: {
    if (orderStatus === TrackingState.Ongoing) {
      remainingDuration = order.estimate?.duration;
    } else {
      percent = 100;
    }
    if (order.scheduled_at && arrivedAt) {
      arrivedDuration = dayjs(order.scheduled_at).diff(dayjs(arrivedAt));
    }
    if (order.scheduled_at && completedAt) {
      completedDuration = dayjs(order.scheduled_at).diff(dayjs(completedAt));
    }
  }

  $: {
    if (remainingDuration) {
      eta = dayjs(time).add(remainingDuration, 's');
      etaDiff = dayjs(order.scheduled_at).diff(eta);
    }
  }

  onMount(() => {
    interval = setInterval(() => {
      if (orderStatus !== TrackingState.Completed) {
        time = new Date();
      } else {
        clearInterval(interval);
      }
    }, 1000);
  });

  onDestroy(() => {
    unsubcribeTrackingData();
    if (typeof interval !== 'undefined') {
      clearInterval(interval);
    }
  });
</script>

{#if driver || order}
  <div class="absolute bottom-4 left-4 right-4 z-20 rounded-lg bg-white shadow-lg sm:bottom-auto sm:top-4 sm:max-w-sm">
    <div class="border-b px-5 py-2.5 text-sm">
      <span class="font-bold">{driver?.name || driver?.driver_handle || 'Driver'}</span>
      {#if orderStatus === TrackingState.Completed && !hideCompleted}
        completed in <span class="font-bold text-gray-500">{prettyDuration(duration * 1000)}</span>
      {:else if orderStatus === TrackingState.Arrived}
        arrived <span class={`font-bold ${getColor(arrivedDuration)?.text}`}>{prettyDuration(arrivedDuration)}</span>
        {durationDiffText(arrivedDuration)}
      {:else if order.estimate?.duration}
        is <span class={`font-bold ${getColor(etaDiff)?.text}`}>{prettyDuration(remainingDuration * 1000)}</span>
        away
      {/if}
    </div>
    <div class={`relative -top-[1.5px] border-t-2 ${borderColor}`} style:width={`${percent}%`}></div>
    <div class="px-5 py-2.5 text-xs">
      <ul class="relative ml-4">
        <li class="border-l border-gray-300 pb-8 pl-8 font-medium">
          <TimelineIcon>
            <Flag class="h-4 w-4 text-gray-400" />
          </TimelineIcon>
          <div>{startStageText}</div>
          {#if order?.started_at}
            <div class="text-xs text-gray-400">
              {dayjs(order.started_at).format(DATE_FORMAT)}
            </div>
          {/if}
        </li>
        <li class={`border-gray-300 pl-8 font-medium ${!hideCompleted ? 'border-l pb-8' : 'pb-4'}`}>
          <TimelineIcon>
            {#if arrivedAt}
              <Bell class="h-4 w-4 text-gray-400" />
            {/if}
          </TimelineIcon>
          <div>{order?.destination?.address}</div>
          {#if arrivedAt}
            <div class={`text-xs ${getColor(arrivedDuration).text}`}>
              Arrived at {dayjs(arrivedAt).format('HH:mm on MMM DD')} ({prettyDuration(arrivedDuration)}
              {durationDiffText(arrivedDuration)})
            </div>
          {:else if eta && orderStatus !== TrackingState.Completed}
            <div class={`text-xs ${getColor(etaDiff).text}`}>
              ETA {eta.format('HH:mm')} ({prettyDuration(etaDiff)}
              {durationDiffText(etaDiff)})
            </div>
          {/if}
        </li>
        {#if !hideCompleted}
          <li class="pb-4 pl-8 font-medium">
            {#if orderStatus === TrackingState.Completed}
              <TimelineIcon>
                <Check class="h-4 w-4 text-gray-400" />
              </TimelineIcon>
              <div>{completeStageText}</div>
              {#if completedAt}
                <div
                  class={`text-xs ${
                    !isNotNullOrUndefined(arrivedAt) ? getColor(completedDuration).text : 'text-gray-400'
                  }`}
                >
                  {dayjs(completedAt).format('HH:mm on MMM DD')}
                  {#if !arrivedAt && completedDuration}
                    ({prettyDuration(completedDuration)}
                    {durationDiffText(completedDuration)})
                  {/if}
                </div>
              {/if}
            {:else}
              <TimelineIcon />
              <div class="relative top-2">{enrouteStageText}</div>
            {/if}
          </li>
        {/if}
      </ul>
      {#if order?.status && order?.metadata && showTerminalNotes}
        <OrderNotes status={order.status} metadata={order.metadata} />
      {/if}
      {#if showAttachment && order.attachments && order.attachments.length > 0}
        <button class="mt-1 flex items-center text-green-600 underline outline-none" on:click={openModal}>
          <Paperclip class="mr-1 h-4 w-4" /> Attachments
        </button>
      {/if}
    </div>
    {#if orderStatus === TrackingState.Completed}
      <div class="flex border-t px-5 py-2.5 font-bold">
        <span>
          {prettyDuration(duration * 1000)}
        </span>
        <span class="ml-auto">
          {meterToKmString(distance)}
        </span>
      </div>
    {/if}
  </div>
{/if}
{#if isModalOpen}
  <AttachmentModal attachments={order.attachments || []} onClose={onCloseModal} />
{/if}
