<script lang="ts">
  import { LocationInferenceType } from '../constants';
  import { extractInferenceTypes, getCountByType } from '../lib/utils';
  import type { Inferences } from '../types/inferences.type';
  import CheckinInferences from './CheckinInferences.svelte';
  import OverlapedInferences from './OverlapedInferences.svelte';
  import ParkingInferences from './ParkingInferences.svelte';

  export let data: Inferences;
</script>

{#if data?.destination_inferences?.locations}
  {#each data.destination_inferences.locations as inference, index}
    {#if extractInferenceTypes(inference?.inferences) === LocationInferenceType.Parking}
      <ParkingInferences
        id={`parking_${index}`}
        polyline={inference?.geometry?.coordinates}
        count={getCountByType(inference?.inferences, LocationInferenceType.Parking)}
      />
    {:else if extractInferenceTypes(inference.inferences) === LocationInferenceType.Checkin}
      <CheckinInferences
        id={`checking_${index}`}
        polyline={inference?.geometry?.coordinates}
        count={getCountByType(inference?.inferences, LocationInferenceType.Checkin)}
      />
    {:else if extractInferenceTypes(inference.inferences) === 'Both'}
      <OverlapedInferences
        id={`overlaped_${index}`}
        polyline={inference?.geometry?.coordinates}
        checkinCount={getCountByType(inference?.inferences, LocationInferenceType.Checkin)}
        parkingCount={getCountByType(inference?.inferences, LocationInferenceType.Parking)}
      />
    {/if}
  {/each}
{/if}
