<script lang="ts">
  import type { LngLatLike, Map } from 'svelte-maplibre';
  import { reduceBounds, trimRoute } from '../../lib/utils';
  import type { PointOrBounds } from '../../types/common.type';
  import { TrackingDataSubscription } from '../../store/app.store';
  import type { Unsubscriber } from 'svelte/store';
  import { TrackingState } from '../../constants';
  import { onDestroy } from 'svelte';

  export let map: Map | null;

  let unsubscribe: Unsubscriber;
  let elements: PointOrBounds[] = [];

  unsubscribe = TrackingDataSubscription.subscribe((data) => {
    const destinationGeometry = data?.destination?.geometry;

    if (destinationGeometry?.type === 'Polygon') {
      elements = destinationGeometry.coordinates[0] as LngLatLike[];
    } else if (destinationGeometry?.type === 'Point') {
      // @ts-ignore
      elements = [destinationGeometry.coordinates];
    }
    if (data.state === TrackingState.Completed) {
      elements = [...elements, ...(data.actualPolyline || [])];
    } else {
      const driverCoords = data.driverLocation.coords;
      let estimatePolyline = data.estimatePolyline;
      if (driverCoords) {
        elements = [...elements, driverCoords];
        estimatePolyline = trimRoute(driverCoords, data.estimatePolyline);
      }
      if (estimatePolyline) {
        elements = [...elements, ...(estimatePolyline || [])];
      }
    }
    const bounds = reduceBounds(elements);
    console.debug('fitting bounds', { elements }, bounds);
    if (map) {
      map.fitBounds(bounds, {
        padding: 50,
        duration: 2000,
        maxZoom: 17,
      });
    }
  });

  onDestroy(() => {
    unsubscribe();
  });
</script>
