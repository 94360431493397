import { writable, type Writable } from 'svelte/store';
import { MAP_LAYERS, TrackingState } from '../constants';
import type { TrackingDataSubscriptionType } from '../lib/types';

export const LayerSubscription = writable(MAP_LAYERS[0]);

export const TrackingDataSubscription: Writable<TrackingDataSubscriptionType> = writable({
  state: TrackingState.Ongoing,
  arrivedAt: null,
  compeletedAt: null,
  remainingDuration: null,
  remainingDistance: null,
  driverLocation: {
    bearing: 0,
    coords: null,
    animate: false,
  },
  estimatePolyline: [],
  actualPolyline: [],
  actualDuration: null,
  actualDistance: null,
  destination: {
    address: null,
    geometry: null,
    radius: null,
  },
});
