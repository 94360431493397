import { TrackingState } from '../constants';
import { TrackingDataSubscription } from '../store/app.store';
import type { ChunkedMessages } from '../types/common.type';
import RestCall from './api';
import { processChunk } from './utils';

const messages: ChunkedMessages = {};

const processEventType = (data: string) => {
  if (data) {
    const parsedData = JSON.parse(data);
    if (parsedData) {
      const body = parsedData?.body;
      const location = body?.location;
      const type = body?.type;
      switch (type) {
        case 'driver_location_update':
          if (location) {
            TrackingDataSubscription.update((prev) => ({
              ...prev,
              driverLocation: {
                coords: location.geometry?.coordinates,
                animate: true,
                bearing: location.bearing,
              },
            }));
          }
          break;

        case 'order_eta_change':
          TrackingDataSubscription.update((prev) => ({
            ...prev,
            remainingDistance: body?.distance,
            remainingDuration: body?.duration,
          }));
          break;

        case 'order_reroute':
          const routeObj = body?.estimate?.route;
          if (routeObj) {
            TrackingDataSubscription.update((prev) => ({
              ...prev,
              remainingDistance: routeObj.distance,
              remainingDuration: routeObj.duration,
              estimatePolyline: [...routeObj.polyline?.coordinates],
            }));
          }
          break;

        case 'order_completion':
          const compeletedAt = body?.completed_at;
          if (compeletedAt) {
            TrackingDataSubscription.update((prev) => ({
              ...prev,
              state: TrackingState.Completed,
              compeletedAt,
            }));
          }
          break;

        case 'order_arrival':
          const arrivedAt = body?.arrived_at;
          if (arrivedAt) {
            TrackingDataSubscription.update((prev) => ({
              ...prev,
              state: TrackingState.Arrived,
              arrivedAt,
            }));
          }
          break;

        case 'order_summary':
          TrackingDataSubscription.update((prev) => ({
            ...prev,
            actualPolyline: body?.locations?.map((loc: [number, number]) => [loc[0], loc[1]]) || [],
            actualDistance: body?.distance,
            actualDuration: body?.duration,
          }));

          break;

        default:
          break;
      }
    }
  }
};

const ConnectWebSocket = async (id: string) => {
  const tokenRes = await RestCall({
    path: '/oauth/token',
    options: {
      method: 'POST',
    },
    data: {
      tracking_id: id,
    },
  });
  if (tokenRes) {
    const ffDomain = import.meta.env.VITE_FF_DOMAIN;
    const streamRes = await RestCall({
      fullURL: `${ffDomain}/orders/tracking/${id}/stream`,
      options: {
        headers: {
          Authorization: `${tokenRes.token_type} ${tokenRes.access_token}`,
        },
      },
    });
    if (streamRes?.stream_url) {
      const socket = new WebSocket(streamRes.stream_url);

      // Function to send a "ping" message to keep the connection alive
      const sendPing = () => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.send(JSON.stringify({ action: 'ping' }));
        }
      };

      // Set up a pinger interval to send "ping" messages every 9 minutes (adjust as needed)
      const pinger = setInterval(sendPing, 9 * 60 * 1000);

      socket.addEventListener('open', () => {
        console.debug('WebSocket connection is open');
      });

      socket.addEventListener('message', (event) => {
        if (event.type === 'chunk') {
          processChunk(messages, event.data, processEventType);
        } else {
          processEventType(event.data);
        }
      });

      socket.addEventListener('close', () => {
        console.debug('WebSocket connection is closed');
        clearInterval(pinger);
      });
    }
  }
};

export default ConnectWebSocket;
