<!-- ImageModal.svelte -->
<script lang="ts">
  import { X } from 'lucide-svelte';
  import { onMount, onDestroy } from 'svelte';
  import type { Attachment } from '../../lib/types';
  import AttachmentItem from './AttachmentItem.svelte';

  export let attachments: Attachment[] = [];
  export let onClose: () => void; // Add a callback for closing the modal

  // Close the modal when the "Escape" key is pressed
  const closeOnEscapeKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  onMount(() => {
    document.addEventListener('keydown', closeOnEscapeKey);
  });

  onDestroy(() => {
    document.removeEventListener('keydown', closeOnEscapeKey);
  });
</script>

<div class="fixed inset-0 z-50 flex items-center justify-center">
  <div class="fixed inset-0 bg-black opacity-50"></div>
  <div class="relative max-w-screen-md rounded-lg bg-white p-6 shadow-lg">
    <button class="absolute right-0 top-0 p-1 text-gray-600 hover:text-gray-800" on:click={onClose}>
      <X class="h-6 w-6" />
    </button>
    <div class="max-h-[90vh] overflow-y-auto">
      <div class={`grid grid-cols-1 gap-4 ${attachments.length > 1 ? 'sm:grid-cols-2' : ''}`}>
        {#each attachments as attachment}
          <AttachmentItem {attachment} />
        {/each}
      </div>
    </div>
  </div>
</div>
