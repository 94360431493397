<script lang="ts">
  import { MapLibre } from 'svelte-maplibre';
  import { LayerSubscription, TrackingDataSubscription } from './store/app.store';
  import getTrackingData from './lib/getTrackingData';
  import type { TrackingData } from './lib/types';
  import { onMount } from 'svelte';
  import { getParamValue } from './lib/utils';
  import { AllowedURLParmas, MAP_LAYERS, TrackingState } from './constants';
  import TileSelector from './components/Map/TileSelector.svelte';
  import CustomError from './components/CustomError.svelte';
  import Loader from './components/Loader.svelte';
  import OrderDestination from './components/OrderDestination.svelte';
  import AutoBounds from './components/Map/AutoBounds.svelte';
  import DriverLocation from './components/DriverLocation.svelte';
  import Estimate from './components/Estimate.svelte';
  import ActualRoute from './components/ActualRoute.svelte';
  import Info from './components/Info.svelte';
  import InferencesComponent from './components/InferencesComponent.svelte';

  const isMaponly = getParamValue(AllowedURLParmas.mapOnly) === 'true';
  const hideControls = getParamValue(AllowedURLParmas.hideControls) === 'true';
  let trackingDataPromise: Promise<TrackingData>;
  let isTrackingIdMissing: boolean = false;
  let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|OperaMini/i.test(navigator.userAgent);
  let containerWidth = window.innerWidth;
  let containerHeight = window.innerHeight;

  onMount(() => {
    const urlId = window.location.pathname?.replace('/', '');
    const tileType = getParamValue(AllowedURLParmas.tileType);
    if (urlId) {
      const showInferences = getParamValue(AllowedURLParmas.showInferences) === 'true';
      trackingDataPromise = getTrackingData(urlId, showInferences);
      isTrackingIdMissing = false;
    } else {
      isTrackingIdMissing = true;
    }
    if (tileType) {
      const foundLayer = MAP_LAYERS.find((l) => l.id === tileType);
      if (foundLayer) {
        LayerSubscription.set(foundLayer);
      }
    }
  });
</script>

{#if isTrackingIdMissing}
  <CustomError errorText="Tracking ID is missing" />
{:else}
  <div
    class="h-screen w-full"
    style:width={isMobile ? `${containerWidth}px` : ''}
    style:height={isMobile ? `${containerHeight}px` : ''}
  >
    <MapLibre style={$LayerSubscription.styleURL} class="relative h-screen w-full" attributionControl={false} let:map>
      {#if !hideControls}
        <TileSelector />
      {/if}
      {#await trackingDataPromise}
        <Loader />
      {:then apiData}
        {#if !isMaponly && apiData?.order}
          <Info order={apiData.order} driver={apiData.driver} />
        {/if}
        {#if $TrackingDataSubscription.state === TrackingState.Completed}
          <ActualRoute {map} />
        {:else}
          <Estimate {map} />
          <DriverLocation />
        {/if}
        <OrderDestination />
        {#if apiData?.inferences}
          <InferencesComponent data={apiData.inferences} />
        {/if}
        <AutoBounds {map} />
      {:catch err}
        <CustomError errorText={err} />
      {/await}
    </MapLibre>
  </div>
{/if}
