<script lang="ts">
  import { slide } from 'svelte/transition';
  import MapLayerIcon from '../../icons/MapLayerIcon.svelte';
  import { Map, Sun, Moon, Satellite } from 'lucide-svelte';
  import { type MapLayerType, type LayerType, MAP_LAYERS } from '../../constants';
  import { LayerSubscription } from '../../store/app.store';
  import { onDestroy, type ComponentType } from 'svelte';

  let isExpanded = false;
  let selectedLayer: MapLayerType;

  function toggleOptions() {
    isExpanded = !isExpanded;
  }

  let unsubscribe = LayerSubscription.subscribe((value) => {
    selectedLayer = value;
  });

  let LayerIconMap: { [key in LayerType]: ComponentType } = {
    street: Map,
    light: Sun,
    dark: Moon,
    statellite: Satellite,
  };

  const handleOptionClick = (layer: MapLayerType) => {
    LayerSubscription.set(layer);
    toggleOptions();
  };

  onDestroy(() => {
    unsubscribe();
  });
</script>

<button
  class="fixed right-4 top-4 z-10 rounded-md border bg-white p-2 text-gray-600 shadow-lg hover:text-black"
  on:click={toggleOptions}
>
  <MapLayerIcon />
</button>

{#if isExpanded}
  <div transition:slide={{ axis: 'x' }} class="fixed right-16 top-4 z-20 grid grid-flow-col space-x-2">
    {#each MAP_LAYERS as mapLayer (mapLayer.id)}
      <button
        class="rounded-md border bg-white p-2 text-gray-400 shadow-lg hover:text-black"
        class:selected={selectedLayer.id === mapLayer.id}
        title={mapLayer.label}
        on:click={() => {
          handleOptionClick(mapLayer);
        }}
      >
        <svelte:component this={LayerIconMap[mapLayer.id]} size={18} />
      </button>
    {/each}
  </div>
{/if}

<style lang="postcss">
  .selected {
    @apply text-black;
  }
</style>
